import React from 'react';

class Table extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h3 className=" firstColor page-header">Porównanie pakietów</h3>
        <br />
        <table className="table table-bordered secondColor">
          <tbody className="text-center">
            <tr className="firstColor table-primary">
              <th colSpan={7}>Lokal mieszkalny ze stałymi elementami</th>
            </tr>
            <tr className="table-primary">
              <td className="align-middle">
                <b>Zakres ubezpieczenia</b>
              </td>
              <td colSpan={6}>
                <b>od wszystkich ryzyk; z ryzykiem powodzi;</b>
                <br />
                przepięcia, szklane przedmioty - limit główna SU
              </td>
            </tr>
            <tr className="table-success">
              <td>
                <b>Składka roczna</b>
              </td>
              <td>
                <b>299</b>
              </td>
              <td>
                <b>339</b>
              </td>
              <td>
                <b>389</b>
              </td>
              <td>
                <b>434</b>
              </td>
              <td>
                <b>492</b>
              </td>
              <td>
                <b>606</b>
              </td>
            </tr>
            <tr className="table-success f-12">
              <td className="align-middle">
                Suma Ubezpieczenia/wartość lokalu
              </td>
              <td className="align-middle">
                <b>300.000</b>
              </td>
              <td className="align-middle">
                <b>400.000</b>
              </td>
              <td className="align-middle">
                <b>500.000</b>
              </td>
              <td className="align-middle">
                <b>600.000</b>
              </td>
              <td className="align-middle">
                <b>800.000</b>
              </td>
              <td className="align-middle">
                <b>1.000.000</b>
              </td>
            </tr>
            <tr className="nonPadding">
              <td>
                Mienie ruchome{' '}
                <small>(limit na kradzież i rabunek w domu 100% SU)</small>
              </td>
              <td className="align-middle">15 000</td>
              <td className="align-middle">15 000</td>
              <td className="align-middle">20 000</td>
              <td className="align-middle">25 000</td>
              <td className="align-middle">30 000</td>
              <td className="align-middle">50 000</td>
            </tr>
            <tr className="nonPadding">
              <td>Wandalizm</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
            </tr>
            <tr className="nonPadding">
              <td>Koszty poszukiwania i usunięcia awarii</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
            </tr>
            <tr className="nonPadding">
              <td>OC w życiu prywatnym na terenie UE</td>
              <td>100 000</td>
              <td>100 000</td>
              <td>100 000</td>
              <td>100 000</td>
              <td>100 000</td>
              <td>100 000</td>
            </tr>
            <tr className="nonPadding">
              <td>Assistance dla domu</td>
              <td colSpan={6}>2 500</td>
            </tr>
            <tr className="nonPadding">
              <td>Franszyza redukcyjna</td>
              <td colSpan={6}>zgodna z owu</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />

        <table className="table table-bordered secondColor">
          <tbody className="text-center">
            <tr className="firstColor table-primary">
              <th colSpan={7}>Budynek mieszkalny ze stałymi elementami</th>
            </tr>
            <tr className="table-primary">
              <td className="align-middle">
                <b>Zakres ubezpieczenia</b>
              </td>
              <td colSpan={6}>
                <b>
                  od wszystkich ryzyk; z ryzykiem powodzi;
                  <br />
                  <small>
                    przepięcia, szklane przedmioty - limit główna SU
                  </small>
                </b>
              </td>
            </tr>
            <tr className="table-success">
              <td>
                <b>Składka roczna</b>
              </td>
              <td>
                <b>502</b>
              </td>
              <td>
                <b>540</b>
              </td>
              <td>
                <b>592</b>
              </td>
              <td>
                <b>669</b>
              </td>
              <td>
                <b>881</b>
              </td>
              <td>
                <b>1295</b>
              </td>
            </tr>{' '}
            <tr className="table-success">
              <td className="align-middle">Suma Ubezpieczenia/wartość domu</td>
              <td className="align-middle">
                <b>500.000</b>
              </td>
              <td className="align-middle">
                <b>700.000</b>
              </td>
              <td className="align-middle">
                <b>850.000</b>
              </td>
              <td className="align-middle">
                <b>1.000.000</b>
              </td>
              <td className="align-middle">
                <b>1.500.000</b>
              </td>
              <td className="align-middle">
                <b>2.000.000</b>
              </td>
            </tr>{' '}
            {/* <tr className="nonPadding">
              <td>niemieszkalny</td>
              <td>20 000</td>
              <td>20 000</td>
              <td>20 000</td>
              <td>30 000</td>
              <td>30 000</td>
              <td>30 000</td>
            </tr>{' '} */}
            <tr className="nonPadding">
              <td>Budowle</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>15 000</td>
              <td>20 000</td>
              <td>20 000</td>
              <td>20 000</td>
            </tr>{' '}
            <tr className="nonPadding">
              <td>
                Mienie ruchome{' '}
                <small>(limit na kradzież i rabunek w domu 100% SU)</small>
              </td>
              <td className="align-middle">20 000</td>
              <td className="align-middle">20 000</td>
              <td className="align-middle">30 000</td>
              <td className="align-middle">50 000</td>
              <td className="align-middle">50 000</td>
              <td className="align-middle">50 000</td>
            </tr>{' '}
            <tr className="nonPadding">
              <td>Wandalizm</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
            </tr>{' '}
            <tr className="nonPadding">
              <td>Koszty poszukiwania i usunięcia awarii</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
            </tr>{' '}
            <tr className="nonPadding">
              <td>Upadek drzew</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
              <td>10 000</td>
            </tr>
            <tr className="nonPadding">
              <td>OC w życiu prywatnym na terenie UE</td>
              <td>100 000</td>
              <td>100 000</td>
              <td>100 000</td>
              <td>100 000</td>
              <td>100 000</td>
              <td>100 000</td>
            </tr>
            <tr className="nonPadding">
              <td>Assistance dla domu</td>
              <td colSpan={6}>2500</td>
            </tr>
            <tr className="nonPadding">
              <td>Franszyza redukcyjna</td>
              <td colSpan={6}>zgodna z owu</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default Table;
